@import "src/data/variables";
@import "src/data/mixins";

.container {
  @include componentContainer;
  flex-direction: column;
  text-align: center;
  @media (min-height: 1100px) {
    min-height: 95vh;
    margin-top: 0;
  }
  .title {
    @include fontTitle;
  }

  .innerContainer {
    @include innerContainerWithShadow;

    .innerBlock {
      .courseTitle {
        @include fontTitleForInnerBlock;
        @media (max-width: 450px) {
          font-size: 18px;
        }
      }

      .courseDescription {
        @include fontDescription;
        font-weight: 500;
        text-align: center;
        @media (max-width: 450px) {
          font-size: 14px;
        }
      }
    }
  }

}
