@import "src/data/variables";
@import "src/data/mixins";

.box {
  .appBar {
    display: flex;
    align-items: center;
    .toolbar {
      max-width: 1000px;
      width: 90%;
      min-height: $appHeaderWidthFirst;
      height: $appHeaderWidthFirst;
      display: flex;
      align-items: center;
      justify-content: space-around;
      @media (max-width: 600px) {
        min-height: $appHeaderWidthSecond;
        height: $appHeaderWidthSecond;
      }
      a {
        text-decoration: none;
        color: white;
        button{
          @media (max-width: 350px) {
            font-size: 11px;
          }
        }
      }
      :hover {
        color: #dedede;
    }

    }
  }

}