@import "src/data/variables";
@import "src/data/mixins";

.box {
  width: 100%;
  opacity: 0.9;
  .toolbar {
    min-height: $appHeaderWidthFirst;
    height: $appHeaderWidthFirst;
    @include flexCenter;
    .icons {
      @include flexCenter;
      .icon{
        margin: 0px 10px;
        img {
          height: 25px;
        }
      }
    }
  }
}