@import "src/data/variables";
@import "src/data/mixins";

.container {
  @include componentContainer;

  .innerContainer {
    position: relative;
    @include innerContainerWithShadow;

    .title {
      @include fontTitle;
      margin-bottom: 30px;
      @media (max-width:400px) {
        font-size: 20px;
      }
    }

    .buttons {
      display: flex;
      justify-content: space-evenly;
      @media (max-width:400px) {
        flex-direction: column;
        align-items: center;
      }
      .wrapper {
        a {
          .button {
            margin-bottom: 20px;
            font-weight: 600;
          }
        }
      }

    }

    .additionalInfo {
      @include fontDescription;
      @media (max-width:400px) {
        font-size: 14px;
      }
    }
  }
}