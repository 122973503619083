@import "src/data/variables";
@import "src/data/mixins";

.container {
  @include componentContainer;
  .variants {
    @include innerContainerWithShadow;
    @include flexCenter;
    flex-direction: column;

    .variantsTitle {
      @include fontTitle;
      @media (max-width: 450px) {
        font-size: 18px;
      }
    }

    .variantsDescription {
      @include flexCenter;
      @media (max-width: 970px) {
        flex-direction: column;
        justify-content: center;
      }
      .restInfoBlock {
        margin-left: 30px;
        @media (max-width: 970px) {
          margin-left: 0;
          margin-top: 10px;
        }
        @media (max-width: 450px) {
          width: 250px;
        }
        .restInfo{
          display: flex;
          .value {
            @include fontRestInfo;
            padding: 5px 0;
            margin-left: 5px;
            @media (max-width: 450px) {
              font-size: 12px;
            }
          }
        }

      }
    }
  }
}
