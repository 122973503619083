@import "src/data/mixins";
@import "src/data/variables";

.innerContainer {
  @include innerContainerWithShadow;
  @include flexCenter;
  flex-direction: column;
  width: 350px;
  @media (max-width: 1200px) {
    width: 450px;
  }
  @media (max-width: 500px) {
    width: 95%;
  }

  .title {
    @include fontTitle;
    font-size: 32px;
    font-weight: bolder;
  }

  .price {
    @include fontRestInfo;
    position: relative;
    font-size: 30px;
    font-weight: 700;
    color: #b01a05;
  }

  .priceWithLane {
    @include fontRestInfo;
    position: relative;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;

    //Only for sale
    &::after {
      position: absolute;
      content: '';
      left: -10px; right: -10px;
      top: 45%;
      opacity: 40%;
      background: red;
      height: 2px;
      transform: rotate(-4deg);
    }
  }

  //Only for sale
  .sale {
    @include fontRestInfo;
    position: relative;
    font-size: 26px;
    color: red;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
    .saleDiscount {
      position: absolute;
      top: -20px; right: -15px;
      font-size: 14px;
      font-weight: 800;
      content: '';
      color: darkred;
    }
  }

  .bulletBlock {
    margin-bottom: 20px;

    .bullet {
      @include fontDescription;
      text-align: center;
      margin: 20px 0;
    }
    :first-child {
      color: darkred;
      font-weight: 700;
    }
  }

  .additions {
    margin-bottom: 20px;

    .addition {
      @include fontDescription;
      text-align: center;
      font-weight: 800;
      margin: 20px 0;
    }
  }

  .button {
    margin-bottom: 20px;
  }
}