@import "src/data/variables";
@import "src/data/mixins";

.container {
  @include componentContainer;
  flex-direction: column;
  text-align: center;

  .title {
    @include fontTitle;
  }

  .innerContainer {
    @include innerContainerWithShadow;
    width: 600px;
    @media (max-width: 650px) {
      width: 95%;
    }
    //@media (max-width: 800px) {
    //  width: 80%;
    //}
    //@media (max-width: 700px) {
    //  width: 95%;
    //}
    .innerBlock {
      .practiceTitle {
        @include fontTitleForInnerBlock;
        margin-bottom: 50px;
        margin-top: 30px;
        @media (max-width: 450px) {
          font-size: 18px;
        }
      }

      .lessonsBlock {
        .lessons {
          position: relative;
          @include fontDescription;
          font-weight: 500;
          text-align: left;
          font-style: italic;
          margin: 50px 0;
          //border: 1px solid red;
          @media (max-width: 450px) {
            font-size: 14px;
          }

          &::after {
            position: absolute;
            content: '';
            bottom: -20px;
            //left: 50%;
            left: 0;
            width: 80px;
            height: 2px;
            background-color: $secondaryColor;
            //transform: translate(-50%, 50%);
          }

          span {
            font-weight: 600;
          }
        }
      }

    }
  }

}
