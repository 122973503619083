@import "../../../data/variables";
@import "../../../data/mixins";

.container {
  @include componentContainer;

  .innerContainer {
    @include innerContainerWithoutShadow;
    @include flexCenter;
    flex-direction: column;

    .title {
      @include fontTitle;
    }
    .bulletBlock {
      margin-bottom: 50px;
      .bullet {
        @include fontDescription;
        text-align: center;
        margin: 20px 0;
      }
    }
    }
  }

