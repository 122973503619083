@import "../../../data/variables";
@import "src/data/mixins";

.container {
  @include componentContainer;
  margin-top: 60px;
  @media (max-width: 880px) {
    align-items: flex-start;
  }
  .innerContainer {
    @include innerContainerWithoutShadow;
    @include flexCenter;
    @media (max-width: 880px) {
      flex-direction: column;
    }

    .image {
      margin-right: 20px;
      width: $mainPhotoWidth;
      height: $mainPhotoHeight;
      @media (max-width: 880px) {
        margin-right: 0;
        width: $mainPhotoWidth * $firstStepPhoto;
        height: $mainPhotoHeight * $firstStepPhoto;
      }

      img {
        border-radius: 10%;
        box-shadow: -5px 5px 10px 0px $secondaryColor;
        width: $mainPhotoWidth;
        @media (max-width: 880px) {
          box-shadow: -5px -5px 10px 0px $secondaryColor;
          width: $mainPhotoWidth * $firstStepPhoto;
        }
      }
    }

    .greetings {
      @include innerContainerWithShadow;
      box-shadow: 5px -5px 10px 0px $secondaryColor;
      @media (max-width: 880px) {
        box-shadow: 5px 5px 10px 0 $secondaryColor;
      }

      .title {
        @include fontTitle;
        margin-bottom: 20px;
      }

      .author {
        @include fontDescription;
        font-weight: 500;
        font-style: italic;
        font-size: 16px;
        text-align: center;
      }
      .mainSign {
        @include fontDescription;
        text-align: center;
        margin: 40px 0;
      }

      .directionToMenu {
        @include fontRestInfo;
        text-align: right;
        margin-top: 15px;
        a{
          text-decoration: none;
          color: $secondaryColor;
        }
      }
    }
  }
}