@import "src/data/variables";
@import "src/data/mixins";

.container {
  @include flexCenter;
  .table {
    min-width: 250px;
    width: 300px;
    background-color: $mainColor;
    @media (max-width: 450px) {
      width: 250px;
    }
    .tableHead {
      .tableRow {
        .tableCell {
          @include fontDescription;
          font-weight: 600;
          text-align: center;
          @media (max-width: 450px) {
            font-size: 14px;
          }
        }
      }
    }

    .tableBody {
      background-color: $mainColor;

      .tableRow {
        .tableCell {
          @include fontDescription;
          font-size: 14px;
          @media (max-width: 450px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}