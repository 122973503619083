@import "../../../data/variables";
@import "../../../data/mixins";

.container {
  @include componentContainer;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: auto;
  margin: 50px 0;
  max-width: unset;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

}


