@import "../../../data/mixins";
@import "../../../data/variables";

.container {
  position: fixed;
  bottom: $appHeaderWidthFirst; right: 15px;
  border-radius: 50%;
  .icon {
    font-size: 45px;
    color: $secondaryColor;
    border-radius: 50%;
  }
}