@import "src/data/mixins";
@import "src/data/variables";

.container {
  @include componentContainer;
  margin-top: $appHeaderWidthFirst + 25px;

  @media (max-width: 600px) {
    margin-top: $appHeaderWidthSecond + 25px;
  }
  .innerContainer {
    @include innerContainerWithShadow;
    @include flexCenter;
    flex-direction: column;

    .title {
      @include fontTitle;
      @media (max-width: 450px) {
        font-size: 18px;
      }
    }

    .valueContainer {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      width: 100%;

      @media (max-width: 450px) {
        flex-direction: column;
      }
      .images {
        width: 100px;

        img {
          width: 100px;
        }
      }

      .bullet {
        margin-left: 20px;
        @media (max-width: 450px) {
          margin-left: 0;
        }
        .bulletTitle{
          @include fontDescription;
          font-weight: 600;
          margin-bottom: 5px;
          @media (max-width: 450px) {
            margin-top: 10px;
          }
        }
        .bulletDescription {
          width: 100%;
          padding-left: 15px;
          @include fontDescription;
          @media (max-width: 450px) {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

    }
  }
}