@import "../../data/variables";
@import "src/data/mixins";

.appContainer {
  position: relative;
  @include appContainer;
  margin-top: $appHeaderWidthFirst;
  min-height: 100vh;
  @media (max-width: 600px) {
    margin-top: $appHeaderWidthSecond;
  }
}

* {
  box-sizing: border-box;
}