@import "../../../data/variables";
@import "../../../data/mixins";

.container {
  @include componentContainer;
  @media (min-height: 1100px) {
    min-height: 95vh;
    margin-top: 0;
  }

  .innerContainer {
    @include innerContainerWithoutShadow;
    @include flexCenter;
    flex-direction: column;

    .title {
      @include fontTitle;
      font-size: 40px;
      font-weight: bolder;
      @media (max-width: 500px) {
        font-size: 32px;
      }
    }
    .onlineHeader {
      @include fontRestInfo;
      font-size: 30px;
      font-style: italic;
      font-weight: normal;
      margin-bottom: 20px;
      @media (max-width: 500px) {
        font-size: 20px;
      }
    }

    .bulletBlock {
      @include flexCenter;
      flex-direction: column;
      margin-top: 25px;

      .bullet {
        @include fontTitleForInnerBlock;
        font-size: 26px;
        @media (max-width: 500px) {
          font-size: 20px;
        }
      }
      .bulletInfo {
        @include fontDescription;
        font-size: 18px;
        text-align: center;
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}


